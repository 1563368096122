import styled, { css } from "styled-components";

import { scope, config } from "./config";

export const ButtonBase = styled.button.attrs(
  ({ type = "button", size = "small" }) => ({ type, size })
)`
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  position: relative;
`;

export const Container = styled(ButtonBase)`
  ${({ size, variant, disabled, fullWidth, noPadding, theme, alignSelf }) => {
    let getVariant = config.variants[variant] || config.variants.primary;
    let _variant = getVariant({ theme, disabled });

    return css`
      cursor: ${disabled ? "not-allowed" : "pointer"};
      background-color: ${_variant.backgroundColor};
      border-radius: ${_variant.borderRadius};
      border: ${_variant.borderColor};
      min-width: ${fullWidth ? "100%" : config.size[size].width};
      align-self: ${alignSelf};
      position: relative;
      display: inline-block;
      padding: ${noPadding
        ? "0rem"
        : config.padding[size] || config.padding.small};
    `;
  }};
`;

export const IconButtonContainer = styled.button`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: none;
  min-width: 1.5rem;
  min-height: 1.5rem;
  width: 2rem;
  height: 2rem;
  border-radius: ${({ theme }) => theme.borderRadius.rounded};
  display: grid;
  place-items: center;
  padding: 0;
  background: rgba(255, 255, 255, 0.1);
  color: ${({ theme }) => theme.colors.grey_3};
`;

export const TextContainer = styled.div`
  width: 100%;
  pointer-events: none;
  user-select: none;
  display: flex;
  flex-direction: ${({ icon, iconRight }) =>
    icon && !iconRight ? "row-reverse" : "row"};
  align-items: center;
  justify-content: center;
  opacity: ${({ loading }) => (loading ? 0 : 1)};
`;

export const Text = styled.p`
  ${({ size, variant, disabled, theme }) => {
    let getVariant = config.variants[variant] || config.variants.primary;
    let getFont = config.font[size] || config.font.small;

    let _variant = getVariant({ theme, disabled });
    let _font = getFont({ theme });

    return css`
      color: ${_variant.textColor};
      text-decoration: ${!disabled && variant === "link"
        ? "underline"
        : "none"};
      font-family: ${theme.font.family.inter_semibold};
      font-size: ${_font.fontSize};
      line-height: ${_font.lineHeight};
    `;
  }};
`;

export const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
`;

export const IconWrapper = styled.div`
  display: grid;
  place-items: center;
  ${({ iconRight }) =>
    css`
  margin-${iconRight ? "left" : "right"}: ${"0.625rem"};
`};
`;

export const IconWrapperFullWidth = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 0.5rem;
`;

Container.defaultProps = scope;
TextContainer.defaultProps = {
  icon: scope.icon,
  iconRight: scope.iconRight,
  loading: scope.loading,
};
Text.defaultProps = {
  size: scope.size,
  variant: scope.variant,
  disabled: scope.disabled,
};
IconWrapper.defaultProps = {
  iconRight: scope.iconRight,
  size: scope.size,
};
