import React, { useRef } from "react";
import { useTheme } from "styled-components";
import PropTypes from "prop-types";

import { Spinner, useRipple } from "libs/purple-ui";

import { config, scope, sizes, variants } from "./config";

import * as S from "./styles";

/**
 * ## Parâmetros
 * @param {string} children - Texto do botão
 * @param {string} size - Tamanho do botão (small, medium, large)
 * @param {string} variant - Estilo do botão (primary, secondary, outlined, danger, link, filledDanger)
 * @param {boolean} disabled - Desabilita o botão
 * @param {boolean} loading - Exibe um loading e desabilita a chamada da função onClick
 * @param {boolean} fullWidth - Faz ocupar 100% da largura disponível
 * @param {boolean} noPadding - Remove o padding padrão do botão
 * @param {node} icon - Renderiza um ícone dentro do botão (Enviar arquivo SVG)
 * @param {boolean} iconRight - Posiciona o ícone a esquerda/direita
 * @param {function} onClick - Função a ser executada ao clicar no botão
 * @param {object} textStyles - Estilos do texto do botão
 * @param {string} alignSelf - Alinhamento do botão
 * ## Exemplo de uso
 * @example
 * <Button
 *  size="small"
 *  variant="primary"
 *  disabled={false}
 *  loading={false}
 *  fullWidth={false}
 *  noPadding={false}
 *  icon={<Icon name="add"/>}
 *  iconRight={true}
 *  onClick={() => console.log('Clicou no botão')}
 *  textStyles={{ textTransform:"uppercase" }}
 *  alignSelf="center"
 * >
 *  Texto
 * </Button>
 * @returns node
 */
export const Button = ({
  type,
  children,
  size,
  variant,
  disabled,
  loading,
  fullWidth,
  noPadding,
  icon,
  iconRight,
  onClick,
  textStyles,
  alignSelf,
  ...rest
}) => {
  const theme = useTheme();

  const handlePress = (event) => {
    if (ref.current) {
      ref.current.blur();
    }
    if (!loading) {
      onClick(event);
    }
  };

  const getColorScheme =
    config.variants[variant] || config.variants[variants.primary];

  const color = getColorScheme({ theme, disabled });
  const ref = useRef();
  const ripples = useRipple({ ref, color: color.ripple });

  return (
    <S.Container
      ref={ref}
      {...rest}
      type={type}
      size={size}
      variant={variant}
      disabled={disabled}
      fullWidth={fullWidth}
      noPadding={noPadding}
      onClick={handlePress}
      alignSelf={alignSelf}
    >
      {ripples}
      <S.TextContainer icon={icon} iconRight={iconRight} loading={loading}>
        <S.Text
          size={size}
          variant={variant}
          disabled={disabled}
          style={textStyles}
        >
          {children}
        </S.Text>
        {icon && !fullWidth && (
          <S.IconWrapper iconRight={iconRight}>
            {React.cloneElement(icon, {
              fontSize: "1.25rem",
              color: color.textColor,
            })}
          </S.IconWrapper>
        )}

        {icon && fullWidth && (
          <S.IconWrapperFullWidth iconRight={iconRight}>
            {React.cloneElement(icon, {
              fontSize: "1.25rem",
              color: color.textColor,
            })}
          </S.IconWrapperFullWidth>
        )}
      </S.TextContainer>
      {loading && (
        <S.LoadingContainer>
          <Spinner color={color.spinner} />
        </S.LoadingContainer>
      )}
    </S.Container>
  );
};

Button.defaultProps = scope;
Button.propTypes = {
  size: PropTypes.oneOf(Object.values(sizes)),
  variant: PropTypes.oneOf(Object.values(variants)),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  noPadding: PropTypes.bool,
  icon: PropTypes.element,
  iconRight: PropTypes.bool,
  onClick: PropTypes.func,
  textStyles: PropTypes.object,
};
