export const sizes = {
  extra_small: "extra_small",
  small: "small",
  medium: "medium",
  large: "large",
};

export const variants = {
  primary: "primary",
  secondary: "secondary",
  terciary: "terciary",
  outlined: "outlined",
  danger: "danger",
  filledDanger: "filledDanger",
  link: "link",
};

export const scope = {
  type: "button",
  size: "medium",
  variant: "primary",
  disabled: false,
  loading: false,
  fullWidth: false,
  noPadding: false,
  icon: undefined,
  iconRight: false,
  alignSelf: "center",
  onClick() {},
};

export const config = {
  variants: {
    [variants.primary]: ({ theme, disabled }) => ({
      backgroundColor: disabled ? theme.colors.grey_1 : theme.colors.primary_6,
      textColor: disabled ? theme.colors.grey_3 : theme.colors.white_pure,
      borderColor: `1px solid ${
        disabled ? theme.colors.grey_1 : theme.colors.primary_6
      }`,
      borderRadius: theme.borderRadius.medium,
      spinner: disabled
        ? [theme.colors.grey_3, theme.colors.grey_4]
        : [theme.colors.white_2, theme.colors.white_pure],
    }),
    [variants.secondary]: ({ theme, disabled }) => ({
      backgroundColor: disabled ? theme.colors.grey_1 : theme.colors.primary_0,
      textColor: disabled ? theme.colors.grey_3 : theme.colors.primary_6,
      borderColor: `1px solid ${
        disabled ? theme.colors.grey_1 : theme.colors.primary_0
      }`,
      borderRadius: theme.borderRadius.medium,
      spinner: disabled
        ? [theme.colors.grey_3, theme.colors.grey_4]
        : [theme.colors.primary_4, theme.colors.primary_6],
    }),
    [variants.terciary]: ({ theme, disabled }) => ({
      backgroundColor: disabled ? theme.colors.grey_1 : theme.colors.primary_3,
      textColor: disabled ? theme.colors.grey_3 : theme.colors.white_pure,
      borderColor: `1px solid ${
        disabled ? theme.colors.grey_1 : theme.colors.primary_3
      }`,
      borderRadius: theme.borderRadius.small,
      spinner: disabled
        ? [theme.colors.grey_3, theme.colors.grey_4]
        : [theme.colors.white_2, theme.colors.white_pure],
    }),
    [variants.outlined]: ({ theme, disabled }) => ({
      backgroundColor: disabled ? theme.colors.grey_1 : theme.colors.white_pure,
      textColor: disabled ? theme.colors.grey_3 : theme.colors.primary_6,
      borderColor: `1px solid ${
        disabled ? theme.colors.grey_1 : theme.colors.primary_6
      }`,
      borderRadius: theme.borderRadius.medium,
      spinner: disabled
        ? [theme.colors.grey_3, theme.colors.grey_4]
        : [theme.colors.primary_4, theme.colors.primary_6],
    }),
    [variants.link]: ({ theme, disabled }) => ({
      backgroundColor: disabled ? theme.colors.grey_1 : theme.colors.white_pure,
      textColor: disabled ? theme.colors.grey_3 : theme.colors.primary_6,
      borderColor: `1px solid ${
        disabled ? theme.colors.grey_1 : theme.colors.white_pure
      }`,
      spinner: disabled
        ? [theme.colors.grey_3, theme.colors.grey_4]
        : [theme.colors.primary_4, theme.colors.primary_6],
    }),
    [variants.danger]: ({ theme, disabled }) => ({
      backgroundColor: disabled ? theme.colors.grey_1 : theme.colors.white_pure,
      textColor: disabled ? theme.colors.grey_3 : theme.colors.danger_5,
      borderColor: `1px solid ${
        disabled ? theme.colors.grey_1 : theme.colors.danger_5
      }`,
      borderRadius: theme.borderRadius.medium,
      spinner: disabled
        ? [theme.colors.grey_3, theme.colors.grey_4]
        : [theme.colors.danger_3, theme.colors.danger_5],
    }),
    [variants.filledDanger]: ({ theme, disabled }) => ({
      backgroundColor: disabled ? theme.colors.grey_1 : theme.colors.danger_5,
      textColor: disabled ? theme.colors.grey_3 : theme.colors.white_pure,
      borderColor: `1px solid ${
        disabled ? theme.colors.grey_1 : theme.colors.danger_5
      }`,
      borderRadius: theme.borderRadius.medium,
      spinner: disabled
        ? [theme.colors.grey_3, theme.colors.grey_4]
        : [theme.colors.white_2, theme.colors.white_pure],
    }),
  },
  font: {
    [sizes.small]: ({ theme }) => ({
      fontSize: theme.font.size.xs,
      lineHeight: theme.font.lineHeight.regular,
    }),
    [sizes.medium]: ({ theme }) => ({
      fontSize: theme.font.size.s,
      lineHeight: theme.font.lineHeight.regular,
    }),
    [sizes.large]: ({ theme }) => ({
      fontSize: theme.font.size.ms,
      lineHeight: theme.font.lineHeight.medium,
    }),
  },
  size: {
    [sizes.extra_small]: {
      width: "7.188rem",
      height: "1.5rem",
    },
    [sizes.small]: {
      width: "6.25rem",
      height: "2rem",
    },
    [sizes.medium]: {
      width: "6.875rem",
      height: "2.5rem",
    },
    [sizes.large]: {
      width: "8.188rem",
      height: "2.875rem",
    },
  },
  padding: {
    [sizes.extra_small]: "0.188rem 0.75rem",
    [sizes.small]: "0.375rem 0.75rem",
    [sizes.medium]: "0.5rem 1rem",
    [sizes.large]: "0.625rem 1.313rem",
  },
};
