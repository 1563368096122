import React, { useEffect, useState } from "react";
import { retry } from "utils/lazy";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { isAuthenticated, isLogged } from "utils";

import Modal from "components/Modal";
import Notification from "components/ToastNotification";
import WidgetStatusPage from "components/WidgetStatusPage";

import { EvaluationJob } from 'modules/evaluationJob';

const Auth = React.lazy(() => retry(() => import("modules/auth")));
const Manager = React.lazy(() => retry(() => import("modules/manager")));
const Supervisor = React.lazy(() => retry(() => import("modules/supervisor")));
const Administrator = React.lazy(() =>
  retry(() => import("modules/administrator"))
);
const CloseerKey = React.lazy(() =>
  retry(() => import("modules/protected/pages/CloseerKey"))
);
const Printing = React.lazy(() => retry(() => import("modules/printing")));
const ExternalEvaluationJob = React.lazy(() =>
  retry(() => Promise.resolve({ default: EvaluationJob }))
);

import CssBaseline from "@material-ui/core/CssBaseline";
import colors from "assets/colors";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuth, redirect } = isAuthenticated();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: redirect, state: { from: rest.path } }} />
        )
      }
    />
  );
};

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !!isLogged() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: rest.path } }} />
        )
      }
    />
  );
};

const Routes = () => (
  <>
    <CssBaseline />
    <BrowserRouter>
      <React.Suspense>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/login" />} />
          <PrivateRoute path="/dashboard" component={Manager} />
          <PrivateRoute path="/spv" component={Supervisor} />
          <PrivateRoute path="/adm" component={Administrator} />
          <ProtectedRoute
            exact
            path="/closeer/unidade/chave-closeer/:id"
            component={CloseerKey}
          />
          <ProtectedRoute
            exact
            path="/print/qrcode"
            component={(props) => <Printing {...props} />}
          />
          <Route
            exact
            path="/closeer/unidade/avaliacao-job/:id"
            component={ExternalEvaluationJob}
          />
          <Route path="/" component={Auth} />
        </Switch>
      </React.Suspense>
      <Notification />
      <Modal />
      {isLogged() && <WidgetStatusPage />}
    </BrowserRouter>
  </>
);

export default Routes;
